var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from 'classnames';
import { Nav, Navbar } from 'react-bootstrap';
import React from 'react';
import { COLLAPSE_LIMIT } from '../const';
import { useAdaptiveNav } from '../hooks/useAdaptiveNav';
import { SuperFastSearch } from '../super-fast-search/SuperFastSearch';
import { NavSubmenu } from '../nav-submenu/NavSubmenu';
import { NavLink } from '../nav-link/NavLink';
export var MainNavContent = function (_a) {
    var navItems = _a.navItems, isLoading = _a.isLoading, navRef = _a.navRef, canAccessAdminLayout = _a.canAccessAdminLayout;
    var _b = useAdaptiveNav(navItems, navRef), priority = _b[0], ordinary = _b[1], isOverflowed = _b[2];
    var showPlaceholder = isLoading || isOverflowed;
    return (React.createElement(React.Fragment, null,
        React.createElement(Navbar.Toggle, { "aria-controls": "main-nav" }),
        React.createElement(Navbar.Collapse, { className: "mw-100", id: "main-nav" },
            React.createElement(Nav, { className: "me-auto nav-content", id: "root-nav" }, priority === null || priority === void 0 ? void 0 :
                priority.map(function (navItem) { return React.createElement(NavLink, { key: navItem.uniqName, navItem: navItem, isLoading: showPlaceholder }); }), ordinary === null || ordinary === void 0 ? void 0 :
                ordinary.map(function (navItem) {
                    var _a;
                    if (priority === null || priority === void 0 ? void 0 : priority.some(function (priorityItem) { return navItem.uniqName === priorityItem.uniqName; })) {
                        return null;
                    }
                    var filteredSubmenu = (_a = navItem.submenu) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
                        var uniqName = _a.uniqName;
                        return !(priority === null || priority === void 0 ? void 0 : priority.some(function (priorityItem) { return uniqName === priorityItem.uniqName; }));
                    });
                    if (!filteredSubmenu) {
                        return React.createElement(NavLink, { key: navItem.uniqName, navItem: navItem, isLoading: showPlaceholder });
                    }
                    if (filteredSubmenu.length >= (navItem.collapseLimit || COLLAPSE_LIMIT)) {
                        return (React.createElement(NavSubmenu, { key: navItem.uniqName, 
                            /* eslint-disable-next-line react-perf/jsx-no-new-object-as-prop */
                            navItem: __assign(__assign({}, navItem), { submenu: filteredSubmenu }), isLoading: showPlaceholder }));
                    }
                    return filteredSubmenu.map(function (item) { return (item.submenu ? (React.createElement(NavSubmenu, { key: navItem.uniqName, navItem: item, isLoading: showPlaceholder })) : (React.createElement(NavLink, { key: item.uniqName, navItem: item, isLoading: showPlaceholder }))); });
                })),
            canAccessAdminLayout && (React.createElement("div", { className: cn('flex-grow-1', { placeholder: showPlaceholder }) },
                React.createElement(SuperFastSearch, null))))));
};
