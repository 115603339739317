export var COLLAPSE_LIMIT = 3;
export var NAVIGATION_CAP = {
    priority: [],
    ordinary: new Array(6)
        .fill(0)
        .map(function (_, idx) { return ({
        uniqName: "cap-".concat(idx),
        url: '',
        caption: 'Is loading',
        parent: 'root',
    }); }),
    employee: [],
};
