var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from 'classnames';
import React from 'react';
import { Nav } from 'react-bootstrap';
import { isLinkActive } from '../utils/is-link-active';
import { CounterWithTip } from '../counter-with-tip/CounterWithTip';
export var NavLink = function (_a) {
    var navItem = _a.navItem, isLoading = _a.isLoading;
    return (React.createElement(Nav.Link, __assign({ active: isLinkActive(navItem), href: navItem.url, className: cn('text-nowrap align-self-start', {
            'ms-sm-auto': navItem.marginLeftAuto,
            'placeholder-wave': isLoading,
        }), target: navItem.inNewTab ? '_blank' : '_self' }, (navItem.data && Object.entries(navItem.data).reduce(function (accumulator, _a) {
        var key = _a[0], value = _a[1];
        accumulator["data-".concat(key)] = value;
        return accumulator;
    }, {}))),
        React.createElement("span", { className: cn({
                'placeholder bg-secondary bg-gradient text-secondary rounded-2': isLoading,
                priority: 'priority' in navItem,
            }) },
            navItem.caption,
            !!navItem.counter && React.createElement(CounterWithTip, { type: "warning", title: "Vacations for approving", count: navItem.counter }))));
};
