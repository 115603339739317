import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { avatarCap } from '../super-fast-search/const';
export var UserProfile = function (_a) {
    var user = _a.user;
    var avatarUrl = user.avatarUrl, email = user.email;
    return (React.createElement(OverlayTrigger, { overlay: React.createElement(Tooltip, { id: "user-email" }, email), placement: "left" },
        React.createElement("div", { className: "user-profile" },
            React.createElement("span", { className: "user-profile-thumb" },
                React.createElement("img", { className: "user-profile-img", width: 24, height: 24, src: avatarUrl || avatarCap, alt: "" })))));
};
