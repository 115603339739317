import cn from 'classnames';
import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { isLinkActive } from '../utils/is-link-active';
export var NavDropdownItem = function (_a) {
    var dropdownItem = _a.dropdownItem;
    return (React.createElement(React.Fragment, null,
        dropdownItem.divider && React.createElement(NavDropdown.Divider, null),
        React.createElement(NavDropdown.Item, { active: isLinkActive(dropdownItem), href: dropdownItem.url, target: dropdownItem.inNewTab ? '_blank' : '_self', className: cn({ disabled: !dropdownItem.url }) }, dropdownItem.caption)));
};
