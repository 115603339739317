import cn from 'classnames';
import { Container, Navbar } from 'react-bootstrap';
import React, { useEffect, useMemo, useRef } from 'react';
// @ts-ignore
import { registerComponents } from 'shared/js/react/integration';
import { NAVIGATION_CAP } from '../const';
import { MainNavContent } from '../main-nav-content/MainNavContent';
import { UserNav } from '../user-nav/UserNav';
import { formatNavigation } from '../utils/format-navigation';
var MainNav = function (_a) {
    var promoLayout = _a.promoLayout, navItems = _a.navItems, canAccessAdminLayout = _a.canAccessAdminLayout, user = _a.user;
    useEffect(function () {
        var _a;
        // toggle 'd-none' on mount/unmount to prevent nav disappearing, while turbolinks loading page
        (_a = document.querySelector('#main-nav-placeholder')) === null || _a === void 0 ? void 0 : _a.classList.add('d-none');
        return function () { var _a; return (_a = document.querySelector('#main-nav-placeholder')) === null || _a === void 0 ? void 0 : _a.classList.remove('d-none'); };
    }, []);
    var _b = useMemo(function () { return (navItems ? formatNavigation(navItems) : NAVIGATION_CAP); }, [navItems]), priority = _b.priority, ordinary = _b.ordinary, employeeNav = _b.employee;
    var navRef = useRef(null);
    var brand = ordinary[0];
    var items = ordinary.slice(1);
    var nav = useMemo(function () { return [priority, items]; }, []);
    return (React.createElement(Navbar, { collapseOnSelect: true, expand: "lg", bg: "dark", variant: "dark", className: "nav px-4", ref: navRef },
        React.createElement(Container, { fluid: !promoLayout, className: cn({ 'p-0 flex-lg-nowrap': !promoLayout }) },
            React.createElement(Navbar.Brand, { href: brand === null || brand === void 0 ? void 0 : brand.url, className: "align-self-start" },
                "/e",
                React.createElement("span", { className: "d-none d-xl-inline" }, "vrone.account")),
            React.createElement(MainNavContent, { canAccessAdminLayout: canAccessAdminLayout, navItems: nav, navRef: navRef }),
            user && React.createElement(UserNav, { employeeNav: employeeNav, user: user }))));
};
registerComponents({ MainNav: MainNav });
