import cn from 'classnames';
import React, { Fragment } from 'react';
import { NavDropdown, Nav } from 'react-bootstrap';
import { NavDropdownItem } from '../nav-dropdown-item/NavDropdownItem';
import { SignOutButton } from '../sign-out-button/SignOutButton';
import { UserProfile } from '../user-profile/UserProfile';
import { isLinkActive } from '../utils/is-link-active';
export var UserNav = function (_a) {
    var employeeNav = _a.employeeNav, user = _a.user;
    return (React.createElement(Nav, { className: cn({ 'ms-auto': user }) },
        React.createElement(NavDropdown, { active: isLinkActive(employeeNav), align: "end", id: "user-main-nav", title: React.createElement(UserProfile, { user: user }) }, employeeNav === null || employeeNav === void 0 ? void 0 : employeeNav.map(function (submenuItem) { return (React.createElement(Fragment, { key: submenuItem.uniqName }, submenuItem.uniqName === 'signOut' ? (React.createElement(SignOutButton, null)) : (React.createElement(NavDropdownItem, { key: submenuItem.uniqName, dropdownItem: submenuItem })))); }))));
};
