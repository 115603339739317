var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var formatNavigation = function (navItems) {
    var priority = [];
    var root = [];
    var employee = [];
    var submenus = navItems.reduce(function (accumulator, item) {
        if (typeof item.priority === 'number') {
            priority[item.priority] = item;
        }
        if (item.parent === 'root') {
            root.push(item);
        }
        if (item.parent === 'employee') {
            employee.push(item);
            return accumulator;
        }
        var existed = accumulator.get(item.parent);
        accumulator.set(item.parent, existed ? __spreadArray(__spreadArray([], existed, true), [item], false) : [item]);
        return accumulator;
    }, new Map());
    // Поддерживаем только 2 уровня вложенности, т.к. на данный момент это максимум и вряд ли в будущем нужно будет больше
    var navFormatted = root === null || root === void 0 ? void 0 : root.map(function (rootItem) {
        var submenu = submenus.get(rootItem.uniqName);
        if (submenu) {
            return __assign(__assign({}, rootItem), { submenu: submenu.map(function (submenuItem) {
                    var nestedSubmenu = submenus.get(submenuItem.uniqName);
                    if (nestedSubmenu) {
                        return __assign(__assign({}, submenuItem), { submenu: nestedSubmenu });
                    }
                    return submenuItem;
                }) });
        }
        return rootItem;
    });
    return { priority: priority.filter(Boolean), ordinary: navFormatted, employee: employee };
};
