import { useEffect, useState } from 'react';
var BS_NAV_LG_BREAKPOINT = 1120;
export var useAdaptiveNav = function (navItems, navRef) {
    var priority = navItems[0], ordinary = navItems[1];
    var _a = useState(undefined), priorityRest = _a[0], setPriorityRest = _a[1];
    var _b = useState(document.documentElement.clientWidth > BS_NAV_LG_BREAKPOINT), overflowed = _b[0], setOverflowed = _b[1];
    useEffect(function () {
        if ((priority === null || priority === void 0 ? void 0 : priority.length) && !priorityRest) {
            setPriorityRest(priority);
        }
    }, [priority, priorityRest]);
    useEffect(function () {
        var observer = new ResizeObserver(function (entry) {
            var target = entry[0].target;
            if (document.documentElement.clientWidth < BS_NAV_LG_BREAKPOINT) {
                return;
            }
            var root = target.querySelector('#root-nav');
            var overflowBy = target.scrollWidth - target.clientWidth;
            if (overflowBy > 0 && root && (priorityRest === null || priorityRest === void 0 ? void 0 : priorityRest.length)) {
                setPriorityRest(function (prev) { return prev === null || prev === void 0 ? void 0 : prev.slice(0, -1); });
                return;
            }
            // для обработки кейса, когда все приоритетные пункты уже убраны, но все равно меню не помещается в 1 строку
            setOverflowed(false);
        });
        if (navRef.current) {
            observer.observe(navRef.current);
        }
        return function () { return observer.disconnect(); };
    }, [navRef, priorityRest === null || priorityRest === void 0 ? void 0 : priorityRest.length]);
    return [priorityRest, ordinary, overflowed];
};
