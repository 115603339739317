import React, { useCallback, useRef } from 'react';
import { NavDropdown } from 'react-bootstrap';
export var SignOutButton = function () {
    var formRef = useRef(null);
    var signOut = useCallback(function () { var _a; return (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.submit(); }, []);
    return (React.createElement(NavDropdown.Item, { onClick: signOut },
        React.createElement("form", { ref: formRef, action: "/jwt/logout", method: "post" },
            React.createElement("input", { name: "_method", value: "delete", type: "hidden" }),
            "Sign out")));
};
