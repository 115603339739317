import classnames from 'classnames';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import React from 'react';
import { NavDropdownItem } from '../nav-dropdown-item/NavDropdownItem';
import { isLinkActive } from '../utils/is-link-active';
export var NavSubmenu = function (_a) {
    var navItem = _a.navItem, isLoading = _a.isLoading;
    var submenu = navItem.submenu, caption = navItem.caption, uniqName = navItem.uniqName, marginLeftAuto = navItem.marginLeftAuto;
    return (React.createElement(NavDropdown, { title: (React.createElement("span", { className: classnames({ 'placeholder bg-secondary bg-gradient text-secondary rounded-2': isLoading }) }, caption)), id: uniqName, active: isLinkActive(navItem), className: classnames({
            'ms-sm-auto': marginLeftAuto,
            'placeholder-wave': isLoading,
        }) }, submenu === null || submenu === void 0 ? void 0 : submenu.map(function (submenuItem) { return (submenuItem.submenu ? (React.createElement(Dropdown, { as: "li", key: submenuItem.uniqName, drop: "end" },
        React.createElement(Dropdown.Toggle, { as: Dropdown.ItemText, className: "cursor-pointer dropdown-item", id: submenuItem.uniqName }, submenuItem.caption),
        React.createElement(Dropdown.Menu, { className: "m-0", id: submenuItem.uniqName }, submenuItem.submenu.map(function (item) { return (React.createElement(Dropdown.Item, { as: "a", key: item.uniqName, href: item.url }, item.caption)); })))) : (React.createElement(NavDropdownItem, { key: submenuItem.uniqName, dropdownItem: submenuItem }))); })));
};
